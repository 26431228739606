import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as AuthJS from '../../../src/authenticate.js';
import { ActivatedRoute, Params } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {

  param: Params;
  previous = new Subject<string>();
  
  constructor(private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(param => {
      this.param = param;
    });
  }

  referrer_origin(): string {
    return this.param?.origin || AuthJS.referrer_origin();
  }
}
