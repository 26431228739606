export const environment = {
  production: false,
  home_url: 'https://www.seech.com',
  oauth_url: 'https://dev-api-auth-seech.azurewebsites.net/api/',
  clientID: '96189fa3-7a61-e811-82de-34e6ad5c2703'
};

/*
   * In development mode, to ignore zone related error stack frames such as
   * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
   * import the following file, but please comment it out in production mode
   * because it will have performance impact when throw error
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
