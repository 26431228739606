import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: 'login', loadChildren: () => import('./modules/features/login/login.module').then(mod => mod.LoginModule) },
  { path: 'signup', loadChildren: () => import('./modules/features/signup/signup.module').then(mod => mod.SignupModule) },
  { path: 'verify-account', loadChildren: () => import('./modules/features/verify-account/verify-account.module').then(mod => mod.VerifyAccountModule) },
  { path: 'forgot-password', loadChildren: () => import('./modules/features/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule) },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
