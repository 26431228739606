
'use strict';
export function referrer_origin() {
    if (!document.referrer) return '';

    const pathArray = String(document.referrer).split('/');
    return pathArray[0] + '//' + pathArray[2];
}

export function isAuthenticated() {
    const token = JSON.parse(localStorage.getItem('token')) || '{}';
    if (!token || JSON.stringify(token) == '{}') { return false }
    const expiresAt = token && token.atExpiresOn ? token.atExpiresOn : '{}';
    return new Date().getTime() < new Date(expiresAt).getTime() ? true : false;
}
var parent_origin = referrer_origin();
// var whitelist = ['http://localhost:8800'];

// function originIsAllowed(origin) {
//     return (whitelist.indexOf(origin) > -1);
// }

if (parent_origin) { // && originIsAllowed(parent_origin))
    parent.postMessage('authenticated=' + isAuthenticated(), parent_origin);
}
